import { Injectable } from '@angular/core';
import { User } from '../../models/data.types';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class UserService {
    user = new ReplaySubject<User>(1)
    cast = this.user.asObservable();

    constructor() {
        var currentUser = localStorage.getItem('currentUser');
        if (currentUser){
          this.changeUser(JSON.parse(currentUser));
        }
    }

    changeUser(user:User){
      this.user.next(user);
      //save user
      localStorage.setItem('currentUser', JSON.stringify(user));  
    }

    logoutUser(){
      this.changeUser(null)
    }

    //not correct
    isLoggedIn(){
      return true;
    }

    // getAll() {
    //     return this.http.get<User[]>('/api/users');
    // }

    // getById(id: number) {
    //     return this.http.get('/api/users/' + id);
    // }

    // create(user: User) {
    //     return this.http.post('/api/users', user);
    // }

    // update(user: User) {
    //     return this.http.put('/api/users/' + user.id, user);
    // }

    // delete(id: number) {
    //     return this.http.delete('/api/users/' + id);
    // }
}