    
    export function generatePageId(){
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength =  characters.length;
        for ( var i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

      export function getUser(){
        var currentUser = localStorage.getItem('currentUser');
        if (currentUser){
          return JSON.parse(currentUser);
        }
        else {
          return null;
        }
    }

    export function logoutUser(){
      localStorage.setItem("currentUser", null)
  }