import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';
import { User } from '../../models/data.types';
import { ApiService } from '../../services/api.service/api.service';

import 'rxjs/add/operator/map'

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient,private apiService: ApiService) {}

login(username: string, password: string) {

    return this.apiService.userLogin(username, password).map(x => 
        
        {
            let user = localStorage.getItem('currentUser');
        
        if (user != null) {
                return user;
        }
            else
        {
           let user : User = new User();
            user.username = username;
            user.password = password;
            return user;
        }
      });
    }

    logout() {
        localStorage.clear();
    }

    public getToken(): string {

        let currentUser : User;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if(currentUser == null)
        {
            return "";
        }
        return currentUser.token;
      }

      public getUser(): User {

        let currentUser : User;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if(currentUser == null)
        {
            return null;
        }
        return currentUser;
      }

      public setToken(token: string) {

        let currentUser : User;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if(currentUser == null)
        {
            currentUser.token = token;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
      }

      public isAuthenticated(): boolean {
        // get the token
        const token = this.getToken();
        // return a boolean reflecting 
        // whether or not the token is expired
        return tokenNotExpired(null, token);
      }
}