
/* Ionic/Angular */
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, ToastController } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { Platform, MenuController } from 'ionic-angular';
import { IonicStorageModule } from '@ionic/storage';

/* Datatables */
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule} from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { DataTablesModule } from 'angular-datatables';

//helpers
import { UserSettings } from '../helpers/userSettings';
import { AlertCtrl } from '../helpers/alertController';

//directives
import { DirectivesModule } from '../directives/directives.module';

/* Services */
import { TokenInterceptor } from '../services/token.interceptor';
import { UserService } from '../services/user.service/user.service';
import { AuthenticationService } from '../services/authentication.service/authentication.service';
import { ApiService } from '../services/api.service/api.service';

/* Providers */
import { SQLitePorterProvider, SQLiteProvider, LocalApiServiceProvider, GeolocationProvider, NetworkProvider } from './app.providers';
import { Network } from '@ionic-native/network/ngx';

/* Pages */
import { DraftPage } from './draft/draft.page';
import { SettingsPage } from './settings/settings';

/* Components (Not using lazy loading) */ 
import { PageComponent } from './components/page/page.component';
import { HeaderComponent } from './components/header/header.component'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

/* Font Awesome */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { DynamicPageService } from 'src/services/dynamic.page.service/dynamic.page.service';
import { LoginActivate } from 'src/services/login-guard/login-guard';
import { Toast } from 'src/helpers/toastController';

library.add(fas, far, fab)

@NgModule({
  declarations: [
    AppComponent,
    DraftPage,
    SettingsPage,
    HeaderComponent,
    PageComponent,
  ],
  entryComponents: [
    AppComponent,
    DraftPage,
    SettingsPage,
    HeaderComponent,
    PageComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    HttpModule,
    HttpClientModule,
    FormsModule,
    NgxSelectModule,
    HttpModule,
    NgxDatatableModule,
    NgxChartsModule,
    DataTablesModule,
    FroalaEditorModule.forRoot(), 
    FroalaViewModule.forRoot(),
    DirectivesModule,
    FontAwesomeModule
  ],
  bootstrap: [AppComponent],
  providers: [
    IonicModule,
    Platform,
    StatusBar,
    SplashScreen,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthenticationService,
    ApiService,
    UserSettings,
    AlertCtrl,
    SQLitePorterProvider,
    SQLiteProvider,
    LocalApiServiceProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Network,
    InAppBrowser,
    GeolocationProvider,
    MenuController,
    NetworkProvider,
    UserService,
    DynamicPageService,
    LoginActivate,
    Toast
  ]
})
export class AppModule {}
