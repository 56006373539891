
/*const sqlReservedWords = ['ADD', 'EXTERNAL', 'PROCEDURE', 'ALL', 'FETCH', 'PUBLIC', 'ALTER', 'FILE', 'RAISERROR', 'AND', 'FILLFACTOR', 'READ', 'ANY', 'FOR', 'READTEXT', 'AS', 'FOREIGN', 'RECONFIGURE', 'ASC', 'FREETEXT', 'REFERENCES', 'AUTHORIZATION', 'FREETEXTTABLE', 'REPLICATION', 'BACKUP', 'FROM', 'RESTORE', 'BEGIN', 'FULL', 'RESTRICT', 'BETWEEN', 'FUNCTION', 'RETURN', 'BREAK', 'GOTO', 'REVERT', 'BROWSE', 'GRANT', 'REVOKE', 'BULK', 'GROUP', 'RIGHT', 'BY', 'HAVING', 'ROLLBACK', 'CASCADE', 'HOLDLOCK', 'ROWCOUNT', 'CASE', 'IDENTITY', 'ROWGUIDCOL', 'CHECK', 'IDENTITY_INSERT', 'RULE', 'CHECKPOINT', 'IDENTITYCOL', 'SAVE', 'CLOSE', 'IF', 'SCHEMA', 'CLUSTERED', 'IN', 'SECURITYAUDIT', 'COALESCE', 'INDEX', 'SELECT', 'COLLATE', 'INNER', 'SEMANTICKEYPHRASETABLE', 'COLUMN', 'INSERT', 'SEMANTICSIMILARITYDETAILSTABLE', 'COMMIT', 'INTERSECT', 'SEMANTICSIMILARITYTABLE', 'COMPUTE', 'INTO', 'SESSION_USER', 'CONSTRAINT', 'IS', 'SET', 'CONTAINS', 'JOIN', 'SETUSER', 'CONTAINSTABLE', 'KEY', 'SHUTDOWN', 'CONTINUE', 'KILL',
'SOME', 'CONVERT', 'LEFT', 'STATISTICS', 'CREATE', 'LIKE', 'SYSTEM_USER', 'CROSS', 'LINENO', 'TABLE', 'CURRENT', 'LOAD', 'TABLESAMPLE', 'CURRENT_DATE', 'MERGE', 'TEXTSIZE', 'CURRENT_TIME', 'NATIONAL', 'THEN', 'CURRENT_TIMESTAMP', 'NOCHECK', 'TO', 'CURRENT_USER', 'NONCLUSTERED', 'TOP', 'CURSOR', 'NOT', 'TRAN', 'DATABASE', 'NULL', 'TRANSACTION', 'DBCC', 'NULLIF', 'TRIGGER', 'DEALLOCATE', 'OF', 'TRUNCATE', 'DECLARE', 'OFF', 'TRY_CONVERT', 'DEFAULT', 'OFFSETS', 'TSEQUAL', 'DELETE', 'ON', 'UNION', 'DENY', 'OPEN', 'UNIQUE', 'DESC', 'OPENDATASOURCE', 'UNPIVOT', 'DISK', 'OPENQUERY', 'UPDATE', 'DISTINCT', 'OPENROWSET', 'UPDATETEXT', 'DISTRIBUTED', 'OPENXML', 'USE', 'DOUBLE', 'OPTION', 'USER', 'DROP', 'OR', 'VALUES', 'DUMP', 'ORDER', 'VARYING', 'ELSE', 'OUTER', 'VIEW', 'END', 'OVER', 'WAITFOR', 'ERRLVL', 'PERCENT', 'WHEN', 'ESCAPE', 'PIVOT', 'WHERE', 'EXCEPT', 'PLAN', 'WHILE', 'EXEC', 'PRECISION', 'WITH', 'EXECUTE', 'PRIMARY', 'WITHIN', 'GROUP', 'EXISTS', 'PRINT', 'WRITETEXT', 'EXIT','PROC', '*'];

const sqlStmnt = "INSERT INTO MOBILEMENU (ID , NO ,SYSTEMNAME ,SEQ ,TITLE ,DESCRIPTION ,LINK ,MENUGROUPID , USERNAME ,EMAIL , LOCAL ) SELECT @ID, @NO , @SYSTEMNAME ,@SEQ ,@TITLE, @DESCRIPTION ,@LINK ,@MENUGROUPID , @USERNAME, @EMAIL, @LOCAL"
*/


/****************************** NEW WAY START ******************************/

export function getParamsFromSql(str){

    var re = /(?:|[ ])@([a-zA-Z0-9_]+)/gm;
    var m;
    var params = [];

    while ((m = re.exec(str)) != null) {
        if (m.index === re.lastIndex) {
            re.lastIndex++;
        }
        params.push(m[1]);
    }


    return removeDuplicates(params);
}


function removeDuplicates(array){
    var toReturn = [];

    for(var i =0; i < array.length ; i++){
        if(toReturn.indexOf(array[i]) == -1) toReturn.push(array[i]);
    }

    return toReturn;
}


export function alignParamsAndColumns(params:any[],columns:string[]){
    var values:any[]=[];

    //if the var name matches a param name, push the param value

    for (var i = 0; i < columns.length; i++){
      for (var j = 0; j < params.length; j++){
        if (columns[i] === params[j].Name){
            values.push(params[j].Value);
        }
      }
    }

    return values;
  }


/****************************** NEW WAY END ******************************/

/****************************** TESTING ******************************/
/*
function getPotentialColumnsFromSql(sqlString){
    var potentialColumns = sqlString.replace(/[{(),@}]/g, ' ').replace(/\s+/g,' ').trim().split(" ");

    //console.log(potentialColumns);

    return potentialColumns;
}

function filterSqlReservedWords(array){

    let filteredArray = array.filter(w => !sqlReservedWords.includes(w));

    return filteredArray;
}

function removeDuplicates(array){
    var toReturn = [];

    for(var i =0; i < array.length ; i++){
        if(toReturn.indexOf(array[i]) == -1) toReturn.push(array[i]);
    }

    return toReturn;
}

export function convertSqlStringToPotentialColumns(sqlString){
    var toReturn = [];

    toReturn = getPotentialColumnsFromSql(sqlString);
    toReturn = filterSqlReservedWords(toReturn);
    toReturn = removeDuplicates(toReturn);

    return toReturn;
}*/


  /******************************* TESTING *******************************/