import { Injectable } from '@angular/core';
import { User } from '../models/data.types';
import { Storage } from '@ionic/storage'

@Injectable()
export class UserSettings {

    constructor(private storage:Storage) {

        /* MAY BE NEEDED IN THE FUTURE
        this.getEnvironment().then(res=>{
            if (!res){
                console.log("set environment")
                this.setEnvironment(environment);   
            }
            else{
                console.log("environment: " + environment)
            }
        })

        this.getLocal().then(res=>{
            if (!res){
                console.log("set local flag")
                this.setLocal(false);
            }
        })

        this.getSplitPane().then(res=>{
            if (!res){
                console.log("set split pane")
                this.setSplitPane(false)
            }
        })*/

    }

    //////////////////////////////////////////////////////////////// USER ////////////////////////////////////////////////////////////////////////////////
    
    setSplitPane(shouldUseSplitPane:boolean){
        this.storage.set("shouldUseSplitPane",shouldUseSplitPane);
    }

    getSplitPane(): Promise<boolean>{
        return this.storage.get('shouldUseSplitPane').then(res=>{
            var shouldUseSplitPane = res;
            return shouldUseSplitPane;
        })

    }

    //////////////////////////////////////////////////////////////// USER ////////////////////////////////////////////////////////////////////////////////

    setUser(user:User){
        this.storage.set("currentUser",user);
    }

    getUser(): Promise<User>{
        return this.storage.get('currentUser').then(res=>{
            var user:any = res;
            return user;
        })

    }

    //////////////////////////////////////////////////////////////// SETTINGS ////////////////////////////////////////////////////////////////////////////////

    setLocal(isLocal){
        this.storage.set("ISLOCAL", isLocal)
    }

    getLocal(): Promise<Boolean>{
        return this.storage.get('ISLOCAL').then(res=>{
            var isLocal:any = res;
            return isLocal;
        })
    }


    setDev(isDev){
        this.storage.set("ISDEV", isDev)
    }

    getDevStatus(): Promise<Boolean>{
        return this.storage.get('ISDEV').then(res=>{
            var isDev:any = res;
            return isDev;
        })
    }

    //////////////////////////////////////////////////////////////// ENVIRONMENTS ////////////////////////////////////////////////////////////////////////////////

    //takes a domain and turns it into an environment ex: allware.us
    /* MAY BE NEEDED IN THE FUTURE
    setEnvironment(environment){
        this.storage.set('ENVIRONMENT', environment)
    }

    
    getEnvironment(): Promise<Environment>{
        return this.storage.get("ENVIRONMENT").then(res=>{
            var env:any = res;
            return env;
        })
    }

    reset(){
        localStorage.setItem("currentUser", null);
        this.setEnvironment(environment);
    }*/
}