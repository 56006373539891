import { Component,} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { User } from 'src/models/data.types';
import { environment } from '../environments/environment';
import { Storage } from '@ionic/storage';
import { ApiService } from '../services/api.service/api.service'
import { MenuController } from 'ionic-angular';
import { UserSettings } from 'src/helpers/userSettings';
import { AppRoutingModule } from './app-routing.module';
import { LocalApiService } from 'src/services/local-api.service/local-api.service';

import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NetworkService } from 'src/services/network.service/network.service';
import { UserService } from 'src/services/user.service/user.service';
import { combineLatest } from 'rxjs';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent  {

  user: User;
  selectedApp:any;
  useSplitPane:boolean;
  online:boolean = true;
  native:boolean = environment.native;
  startUpRoute:string = environment.startUpRoute;
  loginRoute:string = environment.loginRoute;

  constructor(
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private apiService: ApiService,
    private localApiService: LocalApiService,
    public menu: MenuController,
    private settings: UserSettings,
    private nav: AppRoutingModule,
    private statusBar: StatusBar,
    private networkService: NetworkService,
    private userService: UserService,

  ) {
    
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.hide()
      this.statusBar.isVisible = false;

      this.settings.getSplitPane().then(res=>{
        this.useSplitPane = res;
      })

      combineLatest(this.userService.cast, this.networkService.cast, (user, connection)=> ({user, connection})).subscribe(res=>{
        this.user = res["user"]
        this.online = res["connection"]

        this.refreshNav();
      })

    });    
  }

  refreshNav(){
    this.closeUserApps();
    if (this.user){
      if (this.online){
        this.apiService.getUserSystems(this.user.username).then((res:any)=>{
          let apps = res.Items;
          this.user.apps = apps;
        })
      }
      else{
        this.localApiService.selectFromTable("SELECT * FROM ALLWARE_GET_USER_SYSTEMS").then(apps=>{
          this.user.apps = apps;
        })
      }
    }
    else {
      
    }
  }

  toSettingsPage(){
    this.router.navigateByUrl('/settings');
  }

  toDraftPage(link:string){
    if (link){
      this.nav.root(link, {});
    }
    else{
      this.nav.root('', {});
    }
  }

  openApp(app, shouldNavigate){

    if (this.selectedApp == app){
      this.selectedApp = null;
    }
    else {
      app.Pages = [];
      this.selectedApp = app;

      if (app.URL && shouldNavigate){
        this.toDraftPage(app.URL);
      }
  
      if (this.online){

        this.apiService.getApplicationPages(this.user.username, app.ID).then((res:any)=>{
          app.Pages = res.Items;
        })
        .catch(e=>{
          console.log(e)
        })
      }

      //Get Previews from sqlite database
      else {

        this.localApiService.selectFromTable("SELECT * FROM ALLWARE_GET_ALL_USER_PAGES WHERE NO = " + app.ID).then(previews=>{
          app.Pages = previews;
        })
        .catch(e=>{
          console.log(e)
        })
      }

    }
  }

  closeUserApps(){
    
    this.selectedApp = null;

    if (this.user && this.user.apps){
      this.user.apps.forEach(app => {
        if (app.open){
          app.open = false;
        }
      });
    }
  }

  reset(){
    this.apiService.logout().toPromise().then(() => {
      console.log("logged out from server")
    })
    .catch(e=>{
      console.log("error logging out from server", e)
    });
  
    this.localApiService.deleteDB();
    //this.networkService.changeConnection(true)
    this.userService.logoutUser();
    this.nav.root(environment.loginUrl,{})
  }

}
