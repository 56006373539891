import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

/* Available to Dynamic Page and Toolbar */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	declarations: [],
	imports: [
        FontAwesomeModule,
        CommonModule,
        IonicModule,
    ],
	exports: [
        FontAwesomeModule
	]
})
export class SharedModule {}
