// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  native: false,
  //native: true,
  loginUrl: "http://allware.us/page/login",
  domainName: "http://allware.us",
  startUpRoute: "Home",
  loginRoute: "login",
  pageBuilderRoute:"page_builder"
};

