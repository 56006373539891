import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage'; 

//Allows objects to be saved with page if needed

@Injectable({
  providedIn: 'root'
})
export class DataService {
 
  private data = [];
 
  constructor(private storage: Storage) { }
 
  setData(id, data) {
    this.data[id] = data;

    return this.getData(id).then((res)=>{
      if (data != res){
        this.storage.set(id, data);
      }
    })
  }
 
  getData(id) {
    return this.storage.get(id).then((data) => {
        return data;
    });

  }
}