import { NgModule } from '@angular/core';
import { DynamicPageDirective } from './dynamic-page.directive/dynamic-page.directive';

@NgModule({
	declarations: [
		DynamicPageDirective,
	],
	imports: [],
	exports: [
		DynamicPageDirective,
	]
})
export class DirectivesModule {}
