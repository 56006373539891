

import { LocalApiService } from '../services/local-api.service/local-api.service';
import { environment } from '../environments/environment'
import { SQLiteDatabaseConfig } from '@ionic-native/sqlite';

/* NATIVES */
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BehaviorSubject } from 'rxjs';
import { NetworkService } from 'src/services/network.service/network.service';
import { IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

class LocalApiServiceMock {
  getPageCount(){ return 0; }
  getAllPages(){ return []; }
  openDB(db){ }
  saveApplication(app){}
  savePagePreview(){}
  executeSql(insertStmnt, values){}
  getPage(page):Promise<any>{
    var page = null;
    return Promise.resolve(page);
  }
  selectFromTable(string){
    var result = null
    return Promise.resolve(result);
  }
  deleteDB(){}
  then(){ console.log("browser app tried being loaded locally")}
  getDatabaseState(){
    var databaseReady = new BehaviorSubject(true);
    return databaseReady.asObservable();
  }
  savePage(page){
    var page = null;
    return Promise.resolve(page);    
  }
}

export class GeolocationMock {
  getCurrentPosition(){
    return null
  }
}

//SQL file is loaded via script tag in index.html --> <script src="assets/sql/sql.js"></script>
declare var SQL;

export class SQLiteObject{
    _objectInstance: any;

    constructor(_objectInstance: any){
      this._objectInstance = _objectInstance;
    };

    executeSql(statement: string, params: any): Promise<any>{

      return new Promise((resolve,reject)=>{
        try {
          console.log(statement)
          var st = this._objectInstance.prepare(statement,params);
          var rows :Array<any> = [] ;
          while(st.step()) {
            var row = st.getAsObject();
            rows.push(row);
          }
          var payload = {
            rows: {
              item: function(i) {
                return rows[i];
              },
              length: rows.length
            },
            rowsAffected: this._objectInstance.getRowsModified() || 0,
            insertId: this._objectInstance.insertId || void 0
          };
          //save database after each sql query

          var arr : ArrayBuffer = this._objectInstance.export();
          localStorage.setItem("database",String(arr));
          resolve(payload);
        } catch(e){
          reject(e);
        }
      });
    };

    sqlBatch(statements: string[], params: any): Promise<any>{
      return new Promise((resolve,reject)=>{
        try {
          var rows :Array<any> = [];
          for (let statement of statements) {
            console.log(statement)
            var st = this._objectInstance.prepare(statement,params);
            while(st.step()) {
                var row = st.getAsObject();
                rows.push(row);
            }
          }
          var payload = {
            rows: {
              item: function(i) {
                return rows[i];
              },
              length: rows.length
            },
            rowsAffected: this._objectInstance.getRowsModified(),
            insertId: this._objectInstance.insertId || void 0
          };
          //save database after each sql query

          var arr : ArrayBuffer = this._objectInstance.export();
          localStorage.setItem("database",String(arr));
          resolve(payload);
        } catch(e){
          reject(e);
        }
      });
    };
}

/*
  Implemented using edited code from actual cordova plugin
*/
export class SQLitePorterMock {
    /**
     * Trims leading and trailing whitespace from a string
     * @param {string} str - untrimmed string
     * @returns {string} trimmed string
     */


    trimWhitespace(str){
      return str.replace(/^\s+/,"").replace(/\s+$/,"");
    }

    importSqlToDb(db, sql, opts = {}){
      try {
        const statementRegEx = /(?!\s|;|$)(?:[^;"']*(?:"(?:\\.|[^\\"])*"|'(?:\\.|[^\\'])*')?)*/g;
        var statements = sql
          .replace(/(?:\/\*(?:[\s\S]*?)\*\/)|(?:([\s;])+\/\/(?:.*)$)/gm,"") // strip out comments
          .match(statementRegEx);

        if(statements === null || (Array.isArray && !Array.isArray(statements))) statements = [];

        // Strip empty statements
        for(var i = 0; i < statements.length; i++){
          if(!statements[i]){
              delete statements[i];
          }
        }
        return db.sqlBatch(statements)
      } catch(e) {
        console.error(e.message);
      }
    }
}

export class SQLiteMock {

  public create(config: SQLiteDatabaseConfig): Promise<SQLiteObject> {
    var db;
    var storeddb = localStorage.getItem("database");

    if(storeddb) {
      var arr = storeddb.split(',');
      db = new SQL.Database(arr);
    }
    else {
       db = new SQL.Database();
    }

    return new Promise((resolve,reject)=>{
      resolve(new SQLiteObject(db));
    });
  }
}

export class NetworkServiceMock {
  
    private internetConnection = true;
    private online = new BehaviorSubject<boolean>(true)
    cast = this.online.asObservable()
    constructor() { }

    changeConnection(connection){
      this.online.next(connection)
    }

}

export const SQLitePorterProvider = [
  { provide: SQLitePorter, useClass: (environment.native == true) ? SQLitePorter : SQLitePorterMock },
];

export const SQLiteProvider = [
  { provide: SQLite, useClass: (environment.native == true) ? SQLite : SQLiteMock },
];

export const LocalApiServiceProvider = [
  { provide: LocalApiService, useClass: (environment.native == true) ? LocalApiService : LocalApiServiceMock },
];

export const GeolocationProvider = [
  { provide: Geolocation, useClass: (environment.native == true) ? Geolocation : GeolocationMock },
]

export const NetworkProvider = [
  { provide: NetworkService, useClass: (environment.native == true) ? NetworkService : NetworkServiceMock },
]

