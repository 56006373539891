
import { Component, ViewChild, forwardRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Toast } from 'src/helpers/toastController';

@Component({
  selector: 'app-draft',
  templateUrl: './draft.page.html',
  styleUrls: ['./draft.page.scss'],
})

export class DraftPage implements OnInit{

  @ViewChild("page") pageComponent;
  @ViewChild("appHeader") headerComponent;

  pageRoute:string = ""

  constructor( private route: ActivatedRoute, public toast:Toast){
    this.pageRoute = this.route.snapshot.params["id"];
    //this.dynamicPageService.changePageRoute(pageRoute)
  }

  ionViewWillEnter(){
    this.headerComponent.loadHeader(this.pageRoute );
  }

  ngOnInit(){
    //alert("ngoninint")

    this.pageComponent.beginLoadPage(this.pageRoute) ;
  }

  onRefresh(){
    this.pageComponent.beginLoadPage(this.pageRoute);
    //this.toast.prompt("refreshing", "primary")
  }


}
