
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { User, Session, DynamicPage} from '../../models/data.types';
import { UserSettings } from '../../helpers/userSettings';
import { environment } from '../../environments/environment'

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { combineLatest } from 'rxjs/operators';

@Injectable()
export class ApiService {

    isLoggedIn: boolean = false;
    roles: string[];
    redirectUrl: string;
    headers: HttpHeaders;
    authHeaders: HttpHeaders;
    tokenHeaders: HttpHeaders;
    session: Session;
    userName: string;
    localApiUrl: string;
    baseUrl:string;
    
    constructor(private http: HttpClient, public settings: UserSettings) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });        
        this.tokenHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }); 
        this.baseUrl = environment.domainName;
    }

    getDynamicPageByRoute(route:string): Promise<DynamicPage>{
        //should get all with one api call
        return Promise.all([
            this.getPageByRoute(route), 
            this.getPageFunctionsByRoute(route), 
            this.getPageScriptByRoute(route)
        ]).then(function(res){
            
            
            //if page does not exist is handled elsewhere
            var page = res[0];
            
            if (res[1]){
                page.FUNCTIONS = res[1];
            }
            else {
                page.FUNCTIONS = []
            }

            if (res[2]){
                page.SCRIPT = res[2]
            }
            else{
                page.SCRIPT = ""
            }

            return page;
        })
    }

    //have to use this api call because it converts panels to html
    getPageByRoute(route:string): Promise<DynamicPage>{
        return this.get<DynamicPage>('pages/getpagebyroute/' + route).then((page:any) => {  

            var dynamicPage = new DynamicPage();

            //Uppercase to keep consistent
            dynamicPage.DESCRIPTION = page.Description; 
            dynamicPage.FULLTEMPLATE = page.FullTemplate;
            dynamicPage.ID = page.Id;
            dynamicPage.ISPUBLIC = page.IsPublic;
            dynamicPage.IVERSION = page.Iversion;
            dynamicPage.LAYOUTNAME = page.LayoutName; 
            dynamicPage.NAME = page.Name;
            dynamicPage.ROUTE = page.Route;
            dynamicPage.TEMPLATE = page.Template;
            dynamicPage.TITLE = page.Title;
            
            //dynamicPage.SCRIPT = page.Script;
            //dynamicPage.FUNCTIONS = page.Functions
            return dynamicPage;    
        }).catch(e=>{
            console.log(e)
            return null;
        })
    }

    getPageFunctionsByRoute(route:string): Promise<any>{
        return this.getCustom<any>("getpagefunctionsbyroute?route=" + route).then((f:any)=>{
            return f.Items;
        })
        .catch(e=>{
            console.log(e);
            return [];
        })
    }

    getPageScriptByRoute(route:string): Promise<any>{
        return this.getCustom<any>("getpagescriptbyroute?route=" + route).then(result =>{
            return result.Item.script;
          })
          .catch(e=>{
            console.log(e);
            return "console.log('no page script found')";
        })
    }

    //checks if page should hide toolbar
    getPageToolbarByRoute(route:string): Promise<any>{
        return this.getCustom<any>("getpagetoolbarbyroute?route=" + route).then(result =>{
            return result.Item.HideToolbar;
          })
          .catch(e=>{
            console.log(e);
            return "console.log('no page toolbar template found')";
        })
    }

    getPageTitleByRoute(route:string): Promise<any>{
        return this.getCustom<any>("getpagetitlebyroute?route=" + route).then(result =>{
            return result.Item.TITLE;
          })
          .catch(e=>{
            console.log(e);
            return null;
        })
    }

    getCustom<T>(url: string): Promise<T> {
        var localApiUrl = `${this.baseUrl}/service/custom/`;
        return this.http.get(localApiUrl+ url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    get<T>(url: string): Promise<T> {
        var apiUrl = `${this.baseUrl}/service/api/`;
        return this.http.get(apiUrl + url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    put(url: string, o: any) {
        var apiUrl = `${this.baseUrl}/service/api/`;
        this.http.put(apiUrl + url, o, { headers: this.authHeaders }).toPromise().catch(this.handleError);
    }

    putAs<T>(url: string, o: any): Promise<T> {
        var apiUrl = `${this.baseUrl}/service/api/`;
        return this.http.put(apiUrl + url, o, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);

        //apiUrl = the baseUrl ("http://cw.idbpro.com/" + "service/api/")
        //url = the route of the api call ("functions/executeasdatatable/mobilemenu(this is actually an ID but you get the point) )
        //o = parameters

        //full api call = http://cw.idbpro.com/service/api/functions/executeasdatatable/mobilemenu
    }

    post(url: string, o: any) {
        var apiUrl = `${this.baseUrl}/service/api/`;
        this.http.post(apiUrl + url, o, { headers: this.authHeaders }).toPromise().catch(this.handleError);
    }

    postAs<T>(url: string, o: any): Promise<T> {
        var apiUrl = `${this.baseUrl}/service/api/`;
        return this.http.post(apiUrl + url, o, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    delete<T>(url: string): Promise<T> {
        var apiUrl = `${this.baseUrl}/service/api/`;
        return this.http.delete(apiUrl + url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    login(username: string, password: string): Promise<Session> {
        let tokenRequest = 'grant_type=password&username=' + username + '&password=' + password;
        var tokenUrl = `${this.baseUrl}/service/token`;

        return this.http.post(
            tokenUrl,
            tokenRequest, {
                headers: this.tokenHeaders
            })
            .toPromise()
            .then(x => {
                this.isLoggedIn = true;
                return x;
            })
            .catch(x => x);
    }

    ///////////////////////////////////////////// PANELS START ///////////////////////////////////////////////////

    private getPanelsFromTemplate(template:string) {
        var panels = template.match( /\[PANEL .*?\]/g)     
        return panels;
    }

    private getPanelNamesFromPanelDirectives(panels: string[]) {
        var panelNames:string[] = [];

        panels.forEach(panel => {
            var name = panel.match( /"(.*?)"/g)[0]
            var panelName = name.replace(/['"]+/g, '');
            panelNames.push(panelName)
        });
        return panelNames;
    }

    private getPanelWithPanelName(panelName:string): Promise<any>{
        return this.postAs('data/doquery/GetPanelByName?name=', {name: panelName}).then((res:any)=> {
            return res;
        });
    }

    ///////////////////////////////////////////// PANELS END ///////////////////////////////////////////////////

    getUserConfigs(username:string){
        return this.postAs('data/doquery/ALLWARE_GET_USER_CONFIGS', {USERNAME:username}).then(function (res) {
            return res;
        });
    }

    //in use
    getUserSystems(username:string){
        return this.postAs('data/doquery/ALLWARE_GET_USER_SYSTEMS', {GROUPID:0, USERNAME: username}).then(function (res) {
            return res;
        });
    }

    //in use
    getUserPages(username:string){
        return this.postAs('data/doquery/ALLWARE_GET_ALL_USER_PAGES', {USERLOGON:username}).then(function (res) {
            return res;
        });
    }

    //in use
    getUserRoles(username:string){
        return this.postAs('data/doquery/ALLWARE_GET_USER_ROLES', {USERNAME:username}).then(function (res) {
            return res;
        });
    }

    //in use
    getApplicationPages(username:string, appId:string){
        return this.postAs('data/doquery/ALLWARE_GET_APPLICATION_PAGES', {USERLOGON:username, APPID:appId}).then(function (res) {
            return res;
        });
    }

    userLogin(userName: string, passWord: string){
        let tokenRequest = 'grant_type=password&username=' + userName + '&password=' + passWord;
        var tokenUrl = `${this.baseUrl}/service/token`;

        return this.http.post(
            tokenUrl,
            tokenRequest, {
                headers: this.tokenHeaders
            })
            .map(x => {
                localStorage.setItem('session', JSON.stringify(x));
                let session = JSON.parse(JSON.stringify(x));

                let user : User = new User();
                user.username = userName;
                user.password = passWord;
                user.token = JSON.parse(session._body).access_token.toString();
                localStorage.setItem('currentUser', JSON.stringify(user));
                
                this.isLoggedIn = true;
                return x;
            })
            //TO FIX
            //.catch(x => x);
    }

    public logout() {
        var apiUrl = `${this.baseUrl}/service/api/`;
        return this.http.post(apiUrl + 'account/logout', {}, { headers: this.authHeaders })
            .map((response: Response) => {
                return true;
            })
            //.catch((err: Response) => { return Observable.throw(err.json) });
    }
}