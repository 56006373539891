import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { DraftPage } from './draft/draft.page'
import { SettingsPage } from './settings/settings';
import { environment } from '../environments/environment'
import { User } from 'src/models/data.types';
import { getUser } from 'src/app/app.globals';
import { DataService } from '../services/data.service/data.service';
import { NavController } from '@ionic/angular';

export function startUpRoute() {
  var user:User = getUser();

  var route;

  if (user){
    route = user.homePage;
  }
  else {
    route = environment.startUpRoute;
  }

  if (validURL(route)){
    return getLastSegmentFromUrL(route);
  }
  else {
     return route;
  }
}

export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export function getLastSegmentFromUrL(url){
  var parts = url.split('/');
  var lastSegment = parts.pop() || parts.pop(); 

  return lastSegment;
}

const routes: Routes = [

  {
    path: '',
    redirectTo: 'page/' + startUpRoute(),
    pathMatch: 'full'
  },
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'page/:id/:routeParams',
    component: DraftPage,
    pathMatch:'full'
  },
  {
    path: 'page/:id',
    component: DraftPage,
    pathMatch:'full'
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
  
  constructor(public router:Router, public dataService: DataService, public navCtrl: NavController){}

    //this is a funky way of doing it
    private key = "SXGWLZPDOKFIVUHJYTQBNMACERxswgzldpkoifuvjhtybqmncare";
    private cipher(uncoded) {
      uncoded = uncoded.toUpperCase().replace(/^\s+|\s+$/g,"");
      var coded = "";
      var chr;
      for (var i = uncoded.length - 1; i >= 0; i--) {
        chr = uncoded.charCodeAt(i);
        coded += (chr >= 65 && chr <= 90) ? 
          this.key.charAt(chr - 65 + 26*Math.floor(Math.random()*2)) :
          String.fromCharCode(chr); 
        }
      return encodeURIComponent(coded);  
      }
  
  
    public root(destinationUrl:string, dataParams:any){
      
  
      var route;
      var navigationExtras;
      var routeParams = ""
  
      if (validURL(destinationUrl)){
        route = getLastSegmentFromUrL(destinationUrl);
      }
      else {
        route = destinationUrl;
      }
  
      if(dataParams){
        navigationExtras = {
          queryParams: {
            special: JSON.stringify(dataParams)
          }
        }
        routeParams = navigationExtras.queryParams.special;
      }
  
      this.navCtrl.navigateRoot('page/' + route + '/' + this.cipher(routeParams), navigationExtras)
    }
  
    public forward(destinationUrl:string, dataParams:any){
  
      var route;
      var navigationExtras;
      var routeParams = ""
  
      if (validURL(destinationUrl)){
        route = getLastSegmentFromUrL(destinationUrl);
      }
      else {
        route = destinationUrl;
      }
  
      if(dataParams){
        navigationExtras = {
          queryParams: {
            special: JSON.stringify(dataParams)
          }
        }
        routeParams = navigationExtras.queryParams.special;
      }
  
      this.navCtrl.navigateForward('page/' + route + '/' + this.cipher(routeParams), navigationExtras)
    }
  
    public back(destinationUrl:string, dataParams:any){
  
      var route;
      var navigationExtras;
      var routeParams = ""
  
      if (validURL(destinationUrl)){
        route = getLastSegmentFromUrL(destinationUrl);
      }
      else {
        route = destinationUrl;
      }
  
      if(dataParams){
        navigationExtras = {
          queryParams: {
            special: JSON.stringify(dataParams)
          }
        }
        routeParams = navigationExtras.queryParams.special;
      }
  
      this.navCtrl.navigateBack('page/' + route + '/' + this.cipher(routeParams), navigationExtras)
    }

    public pop(){
      this.navCtrl.pop();
    }
  
}
