import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class Toast extends ToastController {

  async prompt(message:string, color:any, duration:number) {
    const toast = await this.create({
      message: message,
      duration: duration,
      position: "top",
      color:color,
      animated: true,
      translucent: true,
      buttons: [
        {
          side: 'end',
          icon: '',
          text: 'ok',
          handler: () => {
            
          }
        }
      ]
    });
    toast.present();
  }

  async presentToastWithOptions(header:string, message:string, []) {
    const toast = await this.create({
      header: header,
      message: message,
      position: "top",
      buttons: [
        {
          side: 'start',
          icon: '',
          text: 'ok',
          handler: () => {
            console.log('Favorite clicked');
          }
        }
      ]
    });
    toast.present();
  }

}


