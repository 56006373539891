import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { Storage } from '@ionic/storage';
import { SQLite, SQLiteObject, SQLiteDatabaseConfig } from '@ionic-native/sqlite/ngx';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import { DataTable, DynamicPage, Application, PagePreview } from '../../models/data.types';
import { UserSettings } from '../../helpers/userSettings';
import { Platform } from '@ionic/angular'

import { alignParamsAndColumns, getParamsFromSql } from '../../helpers/sqliteHelper';

@Injectable()
export class LocalApiService {
    
  database: SQLiteObject;
  private databaseReady: BehaviorSubject<boolean>;

  constructor(public http: Http, private sqlitePorter: SQLitePorter, private storage: Storage, private sqlite: SQLite, private platform: Platform, public settings:UserSettings) {
    this.databaseReady = new BehaviorSubject(false);

    this.platform.ready().then(() => {
      this.openDB();
    });
  }

  runSql(sql:string){
    return this.database.executeSql(sql)
    .then(res=>{
      console.log(res);
      //success message here
    })
    .catch(e=>{
      console.log(e);
      //error message here
    })
  }

  openDB(){
    this.sqlite.create({
      //save the database file with the same name as the base url
      name: "default.db",
      location: 'default'
    })
    .then((db:SQLiteObject)=>{
      this.database = db;

      this.storage.get('database_filled').then(val => {
        console.log(`New database created. Name: ${name}, Location: default/${location}`);
        this.fillDatabase();
      });
    });
  }

  fillDatabase(){
    console.log('filling database...');
    this.http.get('assets/localDatabase.sql')
    .map(res => res.text())
    .subscribe(sql => { 
      this.sqlitePorter.importSqlToDb(this.database, sql)
      .then(data => {
        this.databaseReady.next(true);
        this.storage.set('database_filled', true);
      })
      .catch(e => {
        console.log(e);
      })
    })
  }

  executeSql(sqlStmnt, data){
    return this.database.executeSql(sqlStmnt, data).then(res=>{
      return res;
    })
    .catch(e=>{
      console.log(e)
      return e;
    })
  }

  deleteDB(){

    return this.storage.set('database_filled', false).then(res=>{
      //delete database
      return this.sqlite.deleteDatabase({
        name: 'default.db',
        location: 'default'
      }).then(()=>{
        this.openDB();
      });
    }) 
  }

  //work in progress...
  getSingleObject(sqlStmnt, params){

    var paramValues = []
    if (params.length > 0){
      var columns = getParamsFromSql(sqlStmnt);
      paramValues = alignParamsAndColumns(params,columns);
    }

    return this.database.executeSql(sqlStmnt, paramValues)
    .then(data =>{
      let result = [];
      for (var i=0; i<data.length; i++){
        result.push(data.rows.item(i));
      }
      return result;
    })
    .catch(e =>{
      console.log(e);
    })
  }

  //work in progress...
  execute(sqlStmnt, params){

    var paramValues = []
    if (params.length > 0){
      var columns = getParamsFromSql(sqlStmnt);
      paramValues = alignParamsAndColumns(params,columns);

    }

    return this.database.executeSql(sqlStmnt, paramValues)
    .then(response =>{
      return response;
    })
    .catch(e =>{
      return e;
    })
  }

  //work in progress...
  getDataTable(sqlStmnt:string, params){
    var paramValues = [];
    for (var i = 0; i < params.length; i ++){
      if (params[i].Value != null){
        paramValues.push(params[i].Value);
      }
    }

    return this.database.executeSql(sqlStmnt, paramValues)
    .then(data =>{

      let result = new DataTable;
      result.Items = [];
      if (data.rows.length > 0){
        for (var i = 0; i < data.rows.length; i++) {
          result.Items.push(data.rows.item(i));
        }
      }
      return result;
    })
    .catch(e =>{
      console.log(e);
    })
  }


  deleteTableData(tableName:string){
    return this.database.executeSql("DELETE FROM " + tableName).then(res=>{
      return res
    }).catch(e=>{
      console.log(e)
      return null;
    })
  }





  getApplication(SYSTEMNAME){

    let q = [SYSTEMNAME];
    return this.database.executeSql("SELECT ID, SYSTEMNAME, DESCRIPTION, PAGES, URL, SITEID, SEQ, ICON, TYPE FROM ALLWARE_GET_USER_SYSTEMS WHERE SYSTEMNAME = ?", q).then(data =>{
      if (data.rows.length > 0){
        return data.rows.item(0);
      }
      else {
        return null;
      }
    })
    .catch(e =>{
      console.log(e);
    })
  }

  saveApplication(p:Application){
    return this.getApplication(p.SYSTEMNAME).then(res =>{

      //insert application in database
      if (!res){
        let data = [p.ID, p.SYSTEMNAME, p.DESCRIPTION, JSON.stringify(p.PAGES), p.URL, p.SITEID, p.SEQ, p.ICON, p.TYPE]
        return this.database.executeSql("INSERT INTO ALLWARE_GET_USER_SYSTEMS (ID, SYSTEMNAME, DESCRIPTION, PAGES, URL, SITEID, SEQ, ICON, TYPE) VALUES (?,?,?,?,?,?,?,?,?)", data)
        .then(res => {
          return res;
        }).catch(e => {
          return e;
        });
      }
      //Update application in database    
      else{
        let data = [p.ID, p.SYSTEMNAME, p.DESCRIPTION, JSON.stringify(p.PAGES), p.URL, p.SITEID, p.SEQ, p.ICON, p.TYPE]
        return this.database.executeSql("UPDATE ALLWARE_GET_USER_SYSTEMS SET ID = ?, SYSTEMNAME = ?, DESCRIPTION = ?, PAGES = ?, URL = ?, SITEID = ?, SEQ = ?, ICON = ?, TYPE = ? WHERE SYSTEMNAME = " + p.SYSTEMNAME, data)
        .then(res => {
          return res;
        }).catch(e => {
          return e;
        });
      } 
    });
  }


   selectFromTable(sqlStmnt:string){
      return this.execute(sqlStmnt, []).then(data=>{
        let resultSet = []
        if (data.rows.length > 0){
          for (var i =0; i <data.rows.length; i++){
            resultSet.push(data.rows.item(i))
          }
          return resultSet;
        }
      }).catch(e=>{
        console.log('err: ' + e)
      })
   }

  deleteLocalPages(){
    return this.database.executeSql("DELETE FROM PAGES").then(res=>{
    })
    .catch(e=>{
    })
  }

  getPage(route){
    let q = [route];
    //return this.database.executeSql("SELECT CURRENTVERSION, DATECREATED, DATEMODIFIED, DESCRIPTION, FULLTEMPLATE, ID, ISPUBLIC, IVERSION, LAYOUTNAME, NAME, ROUTE, SCRIPT, TEMPLATE, TITLE, FUNCTIONS FROM PAGES WHERE ROUTE = ?", q).then(data =>{
    return this.database.executeSql("SELECT * FROM PAGES WHERE ROUTE = ?", q).then(data =>{
      if (data.rows.length > 0){
        //will return the first page found... TODO: should specify domain
        var page:DynamicPage = data.rows.item(0);

        //IMPORTANT! functions need to be parsed
        page.FUNCTIONS = JSON.parse(page.FUNCTIONS);

        return page;

      }
      else {
        return null;
      }
    })
    .catch(e =>{
      console.log(e);
    })
  }

  savePage(p:DynamicPage){

    return this.getPage(p.ROUTE).then(res =>{

      //insert page in database
      if (!res){
        let data = [p.CURRENTVERSION, p.DATECREATED, p.DATEMODIFIED, p.DESCRIPTION, p.FULLTEMPLATE, p.ID, p.ISPUBLIC, p.IVERSION, p.LAYOUTNAME, p.NAME, p.ROUTE, p.SCRIPT, p.TEMPLATE, p.TITLE, JSON.stringify(p.FUNCTIONS)]
        return this.database.executeSql("INSERT INTO PAGES (CURRENTVERSION, DATECREATED, DATEMODIFIED, DESCRIPTION, FULLTEMPLATE, ID, ISPUBLIC, IVERSION, LAYOUTNAME, NAME, ROUTE, SCRIPT, TEMPLATE, TITLE, FUNCTIONS) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)", data)
        .then(res => {
          //console.log(p.NAME + " saved (localApiService)")
          return res;
        }).catch(e => {
          //console.log(p.NAME + " was not saved (localApiService)")
          console.log(e)
          return e;
        });
      }
      //Update page in database    
      else{
        let data = [p.CURRENTVERSION, p.DATECREATED, p.DATEMODIFIED, p.DESCRIPTION, p.FULLTEMPLATE, p.ID, p.ISPUBLIC, p.IVERSION, p.LAYOUTNAME, p.NAME, p.SCRIPT, p.TEMPLATE, p.TITLE, JSON.stringify(p.FUNCTIONS), p.ROUTE]
        return this.database.executeSql("UPDATE PAGES SET CURRENTVERSION = ?, DATECREATED = ?, DATEMODIFIED = ?, DESCRIPTION = ?, FULLTEMPLATE = ?, ID = ?, ISPUBLIC = ?, IVERSION = ?, LAYOUTNAME = ?, NAME = ?, SCRIPT = ?, TEMPLATE = ?, TITLE = ?, FUNCTIONS = ? WHERE ROUTE = ?;", data)
        .then(res => {
          //console.log(p.NAME + " updated (localApiService)")
          return res;
        }).catch(e => {
          //console.log(p.NAME + " was not updated (localApiService)");
          console.log(e)
          return e;
        });
      } 
    });
  }

  getAllPages(){
    return this.database.executeSql("SELECT * FROM PAGES", []).then(data =>{
      //console.log(data.rows.item(0));
      if (data.rows.length > 0){
        return data.rows.item;
      }
      else{
        return [];
      }
    }, err => {
      console.log('Error: ' + err);
      return [];
    });
  }

  /* Testing only
  getAllPages(){
    return this.database.executeSql("SELECT * FROM PAGES", []).then(data =>{
      let pages = [];
      if (data.rows.length > 0){
        for (var i = 0; i < data.rows.length; i++) {
          pages.push({Name: data.rows.item(i).PAGENAME, Template: data.rows.item(i).PAGETEMPLATE, Functions: data.rows.item(i).PAGEFUNCTIONS, Title: data.rows.item(i).PAGETITLE});
        }
      }
      return pages;
    }, err => {
      console.log('Error: ' + err);
      return [];
    });
  }*/


  getPageCount(){
    return this.database.executeSql("SELECT * FROM PAGES", []).then(data =>{
      if (data.rows.length > 0){
        return data.rows.length;
      }
      else{
        return 0;
      }
    }, err => {
      console.log('Error: ' + err);
      return [];
    });
  }

  getDatabaseState(){
    return this.databaseReady.asObservable();
  }

}

// export class SQLiteMock {
//   public create(config: SQLiteDatabaseConfig): Promise<SQLiteObject> {
  
//       return new Promise((resolve,reject)=>{
//       resolve(new SQLiteObject(new Object()));
//       });
//   }
// }


// export class SQLitePorterMock {
//   /**
//    * Trims leading and trailing whitespace from a string
//    * @param {string} str - untrimmed string
//    * @returns {string} trimmed string
//    */


//   trimWhitespace(str){
//     return str.replace(/^\s+/,"").replace(/\s+$/,"");
//   }

//   importSqlToDb(db, sql, opts = {}){
//     try {
//       const statementRegEx = /(?!\s|;|$)(?:[^;"']*(?:"(?:\\.|[^\\"])*"|'(?:\\.|[^\\'])*')?)*/g;
//       var statements = sql
//         .replace(/(?:\/\*(?:[\s\S]*?)\*\/)|(?:([\s;])+\/\/(?:.*)$)/gm,"") // strip out comments
//         .match(statementRegEx);

//       if(statements === null || (Array.isArray && !Array.isArray(statements))) statements = [];

//       // Strip empty statements
//       for(var i = 0; i < statements.length; i++){
//         if(!statements[i]){
//             delete statements[i];
//         }
//       }
//       return db.sqlBatch(statements)
//     } catch(e) {
//       console.error(e.message);
//     }
//   }
// }
