
/*Ionic/Angular */
import { Component, ViewEncapsulation, ViewContainerRef, Compiler, NgModule, Pipe, PipeTransform, ViewChild, ElementRef  } from '@angular/core';
import { Directive, ChangeDetectorRef, OnInit } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';

/* Helpers */
import { UserSettings } from "../../helpers/userSettings";
import { environment } from "../../environments/environment"

/* Services */
import { LocalApiService } from "../../services/local-api.service/local-api.service";
import { ApiService } from "../../services/api.service/api.service";

/* Classes */
import { User, DataTable, RequestParameter, QueryStringParameter, NonQuery, SingleObject, DynamicPage, PageFunction, DictionaryItem } from '../../models/data.types';
import { AlertCtrl } from 'src/helpers/alertController';

/* Added Libraries Avail to Page*/
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { AceEditorModule } from 'ng2-ace-editor';
import { FormsModule } from '@angular/forms';
import { BrowserModule, DomSanitizer  } from '@angular/platform-browser';
import * as jQuery from "jQuery";

/* Shared */
import { SharedModule } from '../shared.module'
import { UserService } from 'src/services/user.service/user.service';
import { Toast } from 'src/helpers/toastController';

//For old applications (Robbie Nov 13th 2019)
var _this;

@Directive({
  selector: '[dynamicPage]',
  
})
export class DynamicPageDirective {

  constructor(
    private compiler: Compiler,
    private viewContainer: ViewContainerRef,
  ) { }

  addComponent(page: DynamicPage, api:ApiService, localApi:LocalApiService, queryStringparams: QueryStringParameter[], pageParams: any, Local: boolean) {

    @Component({ 
      template: "<div id= " + page.ROUTE + " style='height:100%; width:100%;'>" + page.TEMPLATE + "</div>", 
      encapsulation: ViewEncapsulation.Emulated,
     })

     class PageTemplateComponent implements OnInit{

      @ViewChild('map') mapElement: ElementRef;

      user : User;
      busy:boolean = false;
      isBusy:boolean = false;
      pageBag: any = {};
      map: any;    

      functions: PageFunction[] = [];
      script: string;
      params:any = pageParams;
      queryParams : QueryStringParameter[] = queryStringparams;
      native:boolean = environment.native;
      iVersion:any;
      
      //api objects
      tables: DictionaryItem<string, DataTable>[] = [];
      doQueryResults: DictionaryItem<string, DataTable>[] = [];
      nonQueries: DictionaryItem<string, NonQuery>[] = [];
      objects: DictionaryItem<string, SingleObject>[] = [];

      $ = jQuery;
      
      constructor(
        private iab: InAppBrowser,
        public settings: UserSettings,
        private userService:UserService,
        private ref: ChangeDetectorRef,
        private geolocation: Geolocation,
        private nav: AppRoutingModule,
        private alert: AlertCtrl,
        private toast: Toast) 
      {
        //For old applications (Robbie Nov 13th 2019)
        _this = this;

        this.userService.cast.subscribe(res=>{
          this.user = res;
        })
       }

      ngOnInit() {
        this.loadPage();
      }

      trustSrcUrl = function(data){
        return this.sanitizer.bypassSecurityTrustResourceUrl(data);
      }

      //refresh binding variables after api call
      detectChanges(){
        this.ref.detectChanges();
      }

      loadPage() {

        if (page.FUNCTIONS && page.FUNCTIONS.length > 0){
          this.functions = page.FUNCTIONS;

          //sort functions
          for (let f of this.functions) {
            if (f.ReturnTypeId == 1) { //void
              this.nonQueries[f.Name] = new NonQuery();
            }
            else if (f.ReturnTypeId == 2) { //dataTable
              this.tables[f.Name] = new DataTable();
            }
            else if (f.ReturnTypeId == 3) { //singleObject
              this.objects[f.Name] = new SingleObject();
            }
            else if (f.ReturnTypeId == 4) { //arrayObject
              this.objects[f.Name] = [];
            }
          }

          try {
            this.doInitFunctions();
          } 
          catch (error) {
            console.log("function error: ");
            console.log(error);
          }
          this.queryParams = queryStringparams;  
        }

        this.script = page.SCRIPT;

        try {
          eval(this.script);
        } 
        catch (error) {
          console.log("script error: ");
          console.log(error);     
        }
        

      }

      private doInitFunctions() {
     
        let fParams: RequestParameter[] = [];

        if (this.functions && this.functions.length > 0){
          for (let f of this.functions) {

            //this could be written with less code
            if (f.LocalCreateStatement && Local){
              this.doLocalStatement(f.Name)
              .then(res=>{
                console.log(res);
  
                if (f.OnLoad) {
                  
                  for (let p of f.Parameters) {
  
                    for (let qsp of queryStringparams) {
                      if (qsp.Name == p.Name && p.DefaultValue != null && p.DefaultValue.startsWith('?')) {
                        let rp = new RequestParameter();
                        rp.Name = qsp.Name;
                        rp.Value = qsp.Value;
                        fParams.push(rp);
                      }
                    }
                  }
  
                  if (fParams.length == 0 && f.Parameters.length > 0) {
                    for (let p of f.Parameters) {
                      if (p.DefaultValue != null) {
                        let rp = new RequestParameter();
                        rp.Name = p.Name;
  
                        if (p.DefaultValue.startsWith('$Global.')) {
                          //something here
                        }
                        else {
                          if (f.IsLocal && Local){
                            //do nothing
                          }
                          else{
                            rp.Value = p.DefaultValue;
                          }
                        }
                        fParams.push(rp);
                      }
                    }
                  }
                  //do function
                  this.do(f.Name,fParams);
                }
              })
              .catch(e=>{
                console.log("error with create statement, not running on start function")
                console.log(e);
              })
            }
            else{
              if (f.OnLoad) {
                  
                for (let p of f.Parameters) {
  
                  for (let qsp of queryStringparams) {
                    if (qsp.Name == p.Name && p.DefaultValue != null && p.DefaultValue.startsWith('?')) {
                      let rp = new RequestParameter();
                      rp.Name = qsp.Name;
                      rp.Value = qsp.Value;
                      fParams.push(rp);
                    }
                  }
                }
  
                if (fParams.length == 0 && f.Parameters.length > 0) {
                  for (let p of f.Parameters) {
                    if (p.DefaultValue != null) {
                      let rp = new RequestParameter();
                      rp.Name = p.Name;
  
                      if (p.DefaultValue.startsWith('$Global.')) {
                        //something here
                      }
                      else {
                        if (f.IsLocal && Local){
                          //do nothing
                        }
                        else{
                          rp.Value = p.DefaultValue;
                        }
                      }
                      fParams.push(rp);
                    }
                  }
                }
                //do function
                this.do(f.Name,fParams);
              }
            }
          }
        }
      }

      ////////////////////////////////////////////////////////////////////////// PageBuilder Methods START //////////////////////////////////////////////////////////////////////////

      doLocalStatement(functionName:string) {
        
        //find the function
        let f = this.functions.find(x => x.Name == functionName);
        if (!f) {
          console.log('Function ' + functionName + ' was not found.');
          return null;
        }

        if (f.LocalCreateStatement){
          return localApi.execute(f.LocalCreateStatement,[])
          .then(response =>{
            console.log(f.Name + " local create statement response: ");
            console.log(response);
          })
          .catch(e=>{
              console.log(e);
            })
          }
      }

      do(functionName: string, o: any) {

        this.busy = true;

        let f = this.functions.find(x => x.Name == functionName);
        if (!f) {
          console.log('Function ' + functionName + ' was not found.');
          return;
        }

        let fParams: RequestParameter[] = [];

        if (o != null) {
          for (let p of f.Parameters) {
            let rp = new RequestParameter();
            rp.Name = p.Name;

            let pval = Reflect.get(o, p.Name);

            if (pval) {
              rp.Value = pval;
            }
            else {
              if (p.DefaultValue != null) {
                // if(p.DefaultValue.startsWith('$Global.')) {
                //     let gVal = this.getGlobal(p.DefaultValue);
                //     console.log('setting ' + p.Name + ' = ' + gVal);
                //     rp.Value = gVal;
                // }
              }
            }
            fParams.push(rp);
          }
        }

        /////////////////////////////////////////////NON QUERY///////////////////////////////////////////
        if (f.ReturnTypeId == 1) { //void
          
          if (f.IsLocal && Local){
            console.log("Connected to local database. Running VOID function: " + f.Name);

            if (f.GlobalBefore != null) {
              eval(f.GlobalBefore);
            }
            if (f.BeforeExec != null) {
              eval(f.BeforeExec);
            }
  
            localApi.execute(f.Sql, fParams).then(response =>{
              console.log(f.Name + " response: ");
              console.log(response);

              this.busy = false;
              this.nonQueries[f.Name] = response;

              if (f.GlobalSuccess != null) {
                eval(f.GlobalSuccess);
              }      
              if (f.OnSuccess != null) {
                eval(f.OnSuccess);
              }      
                          
            }).catch(()=>{
              if (f.GlobalError != null) {
                this.busy = false;
                eval(f.GlobalError); 
              } 
              if (f.OnError != null) {
                this.busy = false;
                eval(f.OnError); 
              } 
              
            });  
          }
        
        else {
          console.log("Connected to online database. Running VOID function: " + f.Name);

          if (f.GlobalBefore != null) {
            eval(f.GlobalBefore);
          }
          if (f.BeforeExec != null) {
            eval(f.BeforeExec);
          }
          

         api.putAs<NonQuery>('functions/executeasnonquery/' + f.FunctionId, fParams).then(response => {
            console.log(f.Name + " response: ");
            console.log(response);

            this.busy = false;
 
            if (f.GlobalSuccess != null) {
              eval(f.GlobalSuccess);
            }      
            if (f.OnSuccess != null) {
              eval(f.OnSuccess);
            }
          }).catch(() => {
              if (f.GlobalError != null) {
                this.busy = false;
                eval(f.GlobalError); 
              } 
              if (f.OnError != null) {
                this.busy = false;
                eval(f.OnError);
              }
            });
          }
        }
        /////////////////////////////////////////////DATA TABLE///////////////////////////////////////////
        else if (f.ReturnTypeId == 2) { //dataTable

          if (f.IsLocal && Local){
            console.log("Connected to local database. Running DATATABLE function: " + f.Name);

              if (f.GlobalBefore != null) {
                eval(f.GlobalBefore);
              }
              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }
    
              localApi.getDataTable(f.Sql, fParams).then(response =>{
                console.log(f.Name + " response: ");
                console.log(response);

                this.busy = false;
                this.tables[f.Name] = response;

                if (f.GlobalSuccess != null) {
                  eval(f.GlobalSuccess);
                }      
                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }              
              }).catch(()=>{
                  if (f.GlobalError != null) {
                    this.busy = false;
                    eval(f.GlobalError); 
                  } 
                  if (f.OnError != null) {
                    this.busy = false;
                    eval(f.OnError); 
                  } 
              });
            }
            
            else {
              console.log("Connected to online database. Running DATATABLE function: " + f.Name);

              if (f.GlobalBefore != null) {
                eval(f.GlobalBefore);
              }
              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }

             api.putAs<DataTable>('functions/executeasdatatable/' + f.FunctionId, fParams).then(response => {
                console.log(f.Name + " response: ");
                console.log(response);

                this.busy = false;
                this.tables[f.Name] = response;

                if (f.GlobalSuccess != null) {
                  eval(f.GlobalSuccess);
                }      
                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              }).catch(() => {
                if (f.GlobalError != null) {
                  this.busy = false;
                  eval(f.GlobalError); 
                } 
                if (f.OnError != null) {
                  this.busy = false;
                  eval(f.OnError);
                }
              });
            }
        }
        /////////////////////////////////////////////SINGLE OBJECT///////////////////////////////////////////
        else if (f.ReturnTypeId == 3) { //singleObject

          if (f.IsLocal && Local){
            console.log("Connected to local database. Running SINGLEOBJECT function: " + f.Name);

              if (f.GlobalBefore != null) {
                eval(f.GlobalBefore);
              }
              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }
    
              localApi.getSingleObject(f.Sql, fParams).then(response =>{
                console.log(f.Name + " response: ");
                console.log(response);

                this.busy = false;               
                this.objects[f.Name] = response;

                if (f.GlobalSuccess != null) {
                  eval(f.GlobalSuccess);
                }      
                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }              
              }).catch(()=>{
                if (f.GlobalError != null) {
                  this.busy = false;
                  eval(f.GlobalError); 
                } 
                  if (f.OnError != null) {
                    this.busy = false;
                    eval(f.OnError); 
                  } 
                });
              }    
          else {
            console.log("Connected to online database. Running SINGLEOBJECT function: " + f.Name);

            if (f.GlobalBefore != null) {
              eval(f.GlobalBefore);
            }
              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }

             api.putAs<SingleObject>('functions/executeassingleobject/' + f.FunctionId, fParams).then(response => {
                  console.log(f.Name + " response: ");
                  console.log(response);
                  
                  this.busy = false;
                  this.objects[f.Name] = response;

                  if (f.GlobalSuccess != null) {
                    eval(f.GlobalSuccess);
                  }      
                  if (f.OnSuccess != null) {
                    console.log(response);
                    eval(f.OnSuccess); 
                  }

                }).catch(() => {
                  if (f.GlobalError != null) {
                    this.busy = false;
                    eval(f.GlobalError); 
                  } 
                  if (f.OnError != null) {
                    this.busy = false;
                    eval(f.OnError);
                  }
                });               
            }  
        }
      }

      refreshPage(){
        this.doInitFunctions();
      }

      //not sure about this yet
      doSql(sql:string){
        localApi.runSql(sql);
      }
      
      //OLD -ROBBIE 1/10/2020 use nav now
      open(url:string, params:any, openInSameTab:boolean, navParams:any){  

        /*  Some sort of url encoding
        if (params){       
          let pArray = Reflect.ownKeys(params);

          pArray.forEach(element => {
            url += '&' + element.toString() + '=' + Reflect.get(params, element.toString());
          });
        }   */  
           
        if (openInSameTab){
          //same page, different params
            this.nav.root(url, params);   
        }
        else {
          //open in browser
          const browser = this.iab.create(url,"",{
            location : 'no',//Or 'no' 
            hidden : 'no', //Or  'yes'
            clearcache : 'yes',
            clearsessioncache : 'yes',
            zoom : 'yes',//Android only ,shows browser zoom controls 
            hardwareback : 'yes',
            mediaPlaybackRequiresUserAction : 'no',
            shouldPauseOnSuspend : 'no', //Android only 
            closebuttoncaption : 'Close', //iOS only
            disallowoverscroll : 'no', //iOS only 
            toolbar : 'yes', //iOS only 
            enableViewportScale : 'no', //iOS only 
            allowInlineMediaPlayback : 'no',//iOS only 
            presentationstyle : 'pagesheet',//iOS only 
          });
        }
      }

      login(username, password, domain){
  
        //from the api service
        api.login(username, password).then(x => {
          
          if (x.error != null) {
            this.alert.simple("Login Failed!", x.error.error_description, "", ["ok"]);
          }
          else {
      
            let session = JSON.parse(JSON.stringify(x));
      
            var user:any = {};
            user.username = username;
            user.password = password;
            user.token = session.access_token.toString();
            user.apps = [];
            
            //set user homepage if the link is native
            if (domain.NATIVE){
              user.homePage = domain.LINK
            }
            else{
              user.homePage = null;
            }
            
            //get Roles
            api.getUserRoles(username).then((res:any)=>{
      
              if (res.Items){
                var roles = res.Items;
                user.roles = roles;
      
                roles.forEach(role => {
                  //if user is developer
                  if(role.id == "49EC68BB-D5F7-4365-BD4E-D615618180E7"){
                   user.isDeveloper = true;
                  }
                }); 
              }
              
              this.userService.changeUser(user);

              if (this.native){
                Promise.all([api.getUserSystems(username), api.getUserPages(username)]).then((data:any)=>{
                  if (data[0] && data[1]){
                    this.dumpToLocalDatabase(data[0].Items, "ALLWARE_GET_USER_SYSTEMS")
                    this.dumpToLocalDatabase(data[1].Items, "ALLWARE_GET_ALL_USER_PAGES")
                  }                 
                //navigate to user home
                this.open(user.homePage, {}, domain.NATIVE, {});
                })
              }    
              else{
                //navigate to user home
                this.open(user.homePage, {}, domain.NATIVE, {});
              }
            })      
          }
        });
      }

      getCurrentLocation(){
        return this.geolocation.getCurrentPosition().then((resp) => {
          // resp.coords.latitude
          // resp.coords.longitude
          return resp
         }).catch((error) => {
           console.log('Error getting location', error);
         });
      }

      dumpToLocalDatabase(items, tableName){

        if (items && items.length > 0){
          for(var i = 0; i < items.length; i++) {
            var item = items[i];
            
            var columns = [];
            var qs = [];
            var values = [];
  
            for (var columnName in item) {
              columns.push(columnName);
              qs.push("?");
              values.push(item[columnName])
            }
            
            let insertStmnt = "INSERT INTO " + tableName + " (" + columns.toString().toUpperCase() + ")" + " VALUES " + " (" + qs.toString() + ")";
  
            localApi.executeSql(insertStmnt, values).then(res=>{
              console.log(res);
            }).catch(e=>{
              this.alert.simple("", "Sqlite Dump Failed", e, ["ok"])
            })
          }
        }

      }


      ////////////////////////////////////////////////////////////////////////// PageBuilder Methods END //////////////////////////////////////////////////////////////////////////

    }


    /*******************************************************************************************************************************************************************************************************************************************************************************/

    
    @Pipe({ name: 'safe' })
    
    class SafePipe implements PipeTransform {
      constructor(private sanitizer: DomSanitizer) {}
      transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    }

    @Pipe({ name: 'safeHtml' })
    class SafeHtmlPipe  implements PipeTransform {
      constructor(private sanitizer: DomSanitizer) {
      }
      transform(value) {
        return this.sanitizer.bypassSecurityTrustHtml(value);
      }
    } 
    @NgModule({
      declarations: [
        PageTemplateComponent,
        SafePipe,
      ],
      imports: [
        BrowserModule,
        FormsModule,
        DataTablesModule,
        NgxDatatableModule,
        AceEditorModule,
        SharedModule
      ]
    })
    class TemplateModule {

      SystemClicked(event): void {
        console.log(event);
      }
    }


    const mod = this.compiler.compileModuleAndAllComponentsSync(TemplateModule);
    const factory = mod.componentFactories.find((comp) =>
      comp.componentType === PageTemplateComponent
    );
    if (this.viewContainer.length > 0) {
      this.viewContainer.remove();
    }
    const component = this.viewContainer.createComponent(factory);
  }
}
