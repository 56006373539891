import { Component, ViewChild, forwardRef, OnInit } from '@angular/core';
import { HttpHeaders,} from "@angular/common/http";
import { ApiService } from '../../../services/api.service/api.service';
import { User,QueryStringParameter, ErrorPage, DynamicPage} from '../../../models/data.types';
import { DynamicPageDirective } from '../../../directives/dynamic-page.directive/dynamic-page.directive';
import { LocalApiService } from '../../../services/local-api.service/local-api.service';
import { AlertCtrl } from 'src/helpers/alertController';
import 'chartjs-plugin-datalabels';

//styling for ace-editor
import 'brace/index';
import 'brace/mode/markdown';
import 'brace/mode/javascript';
import 'brace/mode/sql';
import { ActivatedRoute } from '@angular/router';
import { NetworkService } from 'src/services/network.service/network.service';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})

export class PageComponent {

  @ViewChild(forwardRef(() => DynamicPageDirective)) dynamicPage: DynamicPageDirective;

  constructor(
    private apiService:ApiService,
    private localApiService:LocalApiService,
    private alertCtrl: AlertCtrl,
    private route: ActivatedRoute, 
    private networkService: NetworkService
  ) { 
    
  }

  beginLoadPage(pageRoute){

    combineLatest(this.route.queryParams, this.networkService.cast, (params, connection)=> ({params, connection})).pipe(take(1))
    .subscribe(res=>{     
      var params = {};
      if (res.params && res.params.special){
        params = JSON.parse(res.params.special)
      }
      this.loadPage(res.connection, pageRoute, params)
    })
  }
    
  loadPage(connection: boolean, route:string, params) {

    if (connection){
      //load page from the server
      this.LoadDynamicPage(route, params).then((page:DynamicPage) =>{

        if (page){
          console.log(page.NAME + " loaded online")
        }
      }).catch((e)=>{
        console.log(e);
        var error = "Error loading " + "'" + route + "' from the server";
        this.alertCtrl.simple("Whoops!", ``, e,["ok"]);
        this.LoadErrorPage(error, route, connection)
      });
    }
    else{
      //load the page locally
        this.LoadLocalDynamicPage(route, params).then((page:DynamicPage) =>{
          if (page){
            console.log(page.NAME + " loaded from local database")
          }
        }).catch((e)=>{
          console.log(e);
          var error = "Error loading " + "'" + route + "' from the local database";
          this.LoadErrorPage(error, route, connection)
          this.alertCtrl.simple("Whoops!", ``, e,["ok"]);
        });
    }
  }

  LoadLocalDynamicPage(route:string, params:any){

    let queryStringparams: QueryStringParameter[] = [];
    var isLocal = true;
    
    return this.localApiService.getPage(route).then(savedPage =>{

      //Page was already saved
      if (savedPage){
        this.dynamicPage.addComponent(savedPage, this.apiService, this.localApiService, queryStringparams, params, isLocal );

        return savedPage;
      }
      //No Page Saved
      else{

        var error = "Could not find " + route + " in local database"
        var errorPage = ErrorPage(error);

        this.dynamicPage.addComponent(errorPage, this.apiService, this.localApiService, queryStringparams, params, isLocal );
        
        return null;
      }
    })
    .catch(e =>{
      console.log(e);
      return "Unknown Error! Restart the app!"
    });      
  }

  LoadDynamicPage(route:string,  params:any){

    let currentUser : User;
    currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    if (currentUser != null) {    
      this.apiService.authHeaders = new HttpHeaders();
      this.apiService.authHeaders.append('Content-Type', 'application/json');
      this.apiService.authHeaders.append(
        'Authorization', 'bearer ' + currentUser.token
      );
    }


    return this.apiService.getDynamicPageByRoute(route).then(page => {

      let queryStringparams: QueryStringParameter[] = [];
      var isLocal = false;
      this.dynamicPage.addComponent(page, this.apiService, this.localApiService, queryStringparams, params, isLocal );
      return page;
    });  
  };

  LoadErrorPage(error:string, route:string, isLocal:boolean){
    var errorPage = ErrorPage(error);
    let queryStringparams: QueryStringParameter[] = [];

    this.dynamicPage.addComponent(errorPage, this.apiService, this.localApiService, queryStringparams, {}, isLocal );
  }
}



