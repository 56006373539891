import { Page } from '@ionic/core';

export class LocalDataResult {

    constructor(){
    }

    ErrorMessage: any;
    HasError: boolean;
    Items: any[];
    isBusy: boolean;
    isLoaded: boolean;
    isLoading: boolean;
}

export class RequestParameter {
    Name: string;
    Value: string;
}

export class QueryStringParameter {
    Name: string;
    Value: string;
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}

export class Shell {
    id: number;
    name: string;
    description: string;
    script: string;
    css: string;
    menu: any;
}

export class Alert {
    type: AlertType;
    message: string;
}

export class NonQuery {
    AffectedData: number;
    hasError: boolean;
    errorMessage: string;
}

export class UploadResponse {
    Result: string;
    hasError: boolean;
    errorMessage: string;
}

export class DataTable {
    Items: any;
    HasError: boolean;
    ErrorMessage: string;
    SelectedItemText: string;
    SelectedItemValue: any;
}

export class SingleObject {
    Data: any;
    HasError: boolean;
    ErrorMessage: string;
    SelectedItemText: string;
    SelectedItemValue: any;
}

export class Scalar {
    Data: any;
    HasError: boolean;
    ErrorMessage: string;
}

export class Session {
    access_token: string;
    token_type: string;
    expires_in: number;
    issued: Date;
    expires: Date;
    error: any;
    error_description: string;
}

export interface UserForm {
    Email: string;
    Password: string;
    ConfirmPassword: string;
}

export interface EditUserForm {
    email: string;
    username: string;
    lockoutenabled: boolean;
    phonenumber: string;
    userid?: string;
    accessfailedcount: number;
    emailconfirmed: boolean;
    phonenumberconfirmed: boolean;
    twofactorenabled: boolean;

}

export interface Role {
    rolename: string;
}

export interface RoleResponse {
    id: string;
    name: string;
}

export class DynamicPage {

    /* Serverside Columns */
    CURRENTVERSION:number;
    DATECREATED:any;
    DATEMODIFIED:any;
    DESCRIPTION:string; 
    FULLTEMPLATE:string;
    ID:number;
    ISPUBLIC:number;
    IVERSION:number;
    LAYOUTNAME:string; 
    NAME:string;
    ROUTE:string;
    SCRIPT:string;
    TEMPLATE:string;
    TITLE:string;
    TOOLBARTEMPLATE:string;

    /*Extras */
    FUNCTIONS;
}

export class Domain{
    LINK:string;
    NAME:string;
    NATIVE:string;
    USERNAME:string;
    EMAIL:string;
}

//blank page
export function ErrorPage(error:string){
    var page = new DynamicPage();
    page.TITLE = "404"
    page.NAME = "404"
    page.ROUTE = "404"
    page.ID = 404404404
    page.TEMPLATE = "<div style='margin:20px; height:100vh;'> <h4>" + error + "</h4> <br/> </div>"
    page.SCRIPT = ""
    page.FUNCTIONS = ""
    return page;
}

export interface IPageItem{
    id?: number;
    name: string;
    route: string;
    template: string;
    fulltemplate?: string;
    ispublic: boolean;
}

export interface PageSecurity {
    Id: number;
    PageId: number;
    UserName: string;
    DateCreated: string;
    CanView: boolean;
    CanEdit: boolean;
    CanSecure: boolean;
}

export class User {
    
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token: any;
    isDeveloper: boolean;
    ProfileId: number;
    ProfileName: string;
    homePage:string;
    apps;
    roles:any;
}

export class Application {
    ID:number;
    SYSTEMNAME:string;
    DESCRIPTION:string;
    PAGES:DynamicPage[];
    URL:string;
    SITEID:number;
    SEQ:number;
    ICON:string;
    TYPE:string;

    /*Extras */
    open:boolean;

    constructor(ID:number, SYSTEMNAME: string, DESCRIPTION:string, PAGES: DynamicPage[], URL: string, SITEID: number,  SEQ: number, ICON: string, TYPE: string){
        this.ID = ID;
        this.SYSTEMNAME = SYSTEMNAME;
        this.DESCRIPTION = DESCRIPTION;
        this.PAGES = PAGES;
        this.URL = URL;
        this.SITEID = SITEID;
        this.SEQ = SEQ;
        this.ICON = ICON;
        this.TYPE = TYPE;
    }
}

export class PagePreview {

    ID: number;
    SITEID: number;
    NO: number;
    SYSTEMSEQ: number;
    SYSTEMNAME: string;
    SEQ: number;
    TITLE: string;
    DESCRIPTION:string;
    LINK: string;
    PROFILEID: number;
    USERNAME: string;
    EMAIL: string;
    STARTPAGE: string;
    MENUICON: string;
    APPLICATIONID: number;
    LINKTYPE: string;
    TYPE: string;

    constructor(    
        ID: number,
        SITEID: number,
        NO: number,
        SYSTEMSEQ: number,
        SYSTEMNAME: string,
        SEQ: number,
        TITLE: string,
        DESCRIPTION:string,
        LINK: string,
        PROFILEID: number,
        USERNAME: string,
        EMAIL: string,
        STARTPAGE: string,
        MENUICON: string,
        APPLICATIONID: number,
        LINKTYPE: string,
        TYPE: string){
            this.ID = ID;
            this.SITEID = SITEID;
            this.NO = NO;
            this.SYSTEMSEQ = SYSTEMSEQ;
            this.SYSTEMNAME = SYSTEMNAME;
            this.SEQ = SEQ;
            this.TITLE = TITLE;
            this.DESCRIPTION = DESCRIPTION;
            this.LINK = LINK;
            this.PROFILEID = PROFILEID;
            this.USERNAME = USERNAME;
            this.EMAIL = EMAIL;
            this.STARTPAGE = STARTPAGE;
            this.MENUICON = MENUICON;
            this.APPLICATIONID = APPLICATIONID;
            this.LINKTYPE = LINKTYPE;
            this.TYPE = TYPE;
    }
}

export function createUniqueRouteId(url:string){
    var splits = splitRoute(url);
    var domain = splits[0];
    var route = splits[2];
    var uniqueRouteId = domain + ":" + route;

    return uniqueRouteId;
}

function splitRoute(url:string){
    var routes = url.replace("http://", "").replace("www.","");
    var array = routes.split("/");
    return array;
}

/*
function getRouteFromUrl(url:string){
    var urlStrings = url.split("/");
    return urlStrings[urlStrings.length - 1];
}

function getDomainFromUrl(url:string){
    var urlStrings = url.split("/");

    var domain = urlStrings[2];

    if (domain.includes('www.')){
      domain = domain.replace('www.','');        
    }

    return domain;
}
*/

export interface ConnectionString {
    Id: number;
    name: string;
    connectionString: string;
}

export interface Function {
    Id: number;
    PageId: number;
    Name: string;
    ConnectionId: number;
    TriggerId: number;
    ReturnTypeId: number;
    Parameters: Parameter[];
    IsProcedure: boolean;
    Timeout: number;
    Sql: string;
    BeforeExec: string;
    OnSuccess: string;
    OnError: string;
    IsLocal:boolean;
    LocalCreateStatement;
}

export interface PageFunction {
    Id: number;
    PanelId: number;
    ReturnTypeId: number;
    Name: string;
    OnLoad: boolean;
    OnChange: boolean;
    BeforeExec: string;
    OnSuccess: string;
    OnError: string;
    Parameters: Parameter[];
    RunLocally:Boolean;
    Sql:string;
    IsProcedure:Boolean;
    Description:string;
    FunctionId:number;
    IsLocal:boolean;
    LocalCreateStatement;
    
    GlobalSuccess:string;
    GlobalError:string;
    GlobalBefore:string; 
}

export interface FunctionParameter {
    Id: number;
    Name: string;
    DefaultValue: string;
}

export interface Parameter {
    Id: number;
    FunctionId: number;
    Name: string;
    DefaultValue: string;
    IsRequired: boolean;
}

export interface Trigger {
    Id: number;
    Name: string;
}

export interface ReturnType {
    Id: number;
    Name: string;
}
export interface DictionaryItem<K, V> {
    0: K,
    1: V

    isBusy:boolean;
    isLoading:boolean;
    isLoaded:boolean;
}
export class LocalPage { 
    page: DynamicPage; 
    pagename: string; 
    isLocal: boolean;

    constructor(page, pagename, isLocal){
        this.page = page;
        this.pagename = pagename;
        this.isLocal = isLocal;
    }
 };

 export class Table {
    Items: any[];
}

export interface IKeyedCollection<T> {
    Add(key: string, value: T);
    ContainsKey(key: string): boolean;
    Count(): number;
    Item(key: string): T;
    Keys(): string[];
    Remove(key: string): T;
    Values(): T[];
}

export class KeyedCollection<T> implements IKeyedCollection<T> {
    private items: { [index: string]: T } = {};
 
    private count: number = 0;
 
    public ContainsKey(key: string): boolean {
        return this.items.hasOwnProperty(key);
    }
 
    public Count(): number {
        return this.count;
    }
 
    public Add(key: string, value: T) {
        if(!this.items.hasOwnProperty(key))
             this.count++;
 
        this.items[key] = value;
    }
 
    public Remove(key: string): T {
        var val = this.items[key];
        delete this.items[key];
        this.count--;
        return val;
    }
 
    public Item(key: string): T {
        return this.items[key];
    }
 
    public Keys(): string[] {
        var keySet: string[] = [];
 
        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                keySet.push(prop);
            }
        }
 
        return keySet;
    }
 
    public Values(): T[] {
        var values: T[] = [];
 
        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                values.push(this.items[prop]);
            }
        }
 
        return values;
    }
}