import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'

@Injectable()
export class DynamicPageService {
    private route = new BehaviorSubject<string>("")
    getRoute = this.route.asObservable();

    constructor() {

    }

    changePageRoute(newRoute:string){
        this.route.next(newRoute);
    }

}