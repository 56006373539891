import { AlertController } from '@ionic/angular';
import { Injectable } from "@angular/core";

@Injectable()
export class AlertCtrl extends AlertController{

    async simple(header:string, subHeader:string, message:string, buttons:any[]) {
        const alert = await super.create({
          header: header,
          subHeader: subHeader,
          message: message,
          buttons: buttons
        });
    
        await alert.present();
      }
    
      async multipleButtons(header:string, subHeader:string, message:string, buttons:any[]) {
        const alert = await super.create({
          header: header,
          subHeader: subHeader,
          message: message,
          buttons: buttons
        });
    
        await alert.present();
      }
    
      async confirm(header:string, message:string, onConfirmText:string, onConfirm:Function, onCancelText:string, onCancel:Function) {
        const alert = await super.create({
          header: header,
          message: message,
          buttons: [
            {
              text: onCancelText,
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                onCancel();
              }
            }, {
              text: onConfirmText,
              handler: () => {
                onConfirm();
              }
            }
          ]
        });
    
        await alert.present();
      }
    
      //search ionic alerts for 'input' examples
      async prompt(header:string, inputs:any[], onConfirmText:string, onConfirm:Function, onCancelText:string, onCancel:Function) {
        const alert = await super.create({
          header: header,
          inputs: inputs,
          buttons: [
            {
              text: onCancelText,
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                onCancel();
              }
            }, {
              text: onConfirmText,
              handler: () => {
                onConfirm();
              }
            }
          ]
        });
    
        await alert.present();
      }
}