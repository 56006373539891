import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { catchError, take, finalize } from "rxjs/operators";
//import { environment } from '../../environments/environment.prod';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service/authentication.service';
import { UserSettings } from '../helpers/userSettings';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  isRefreshingToken: boolean = false;
  auth : AuthenticationService;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  tokenHeaders: HttpHeaders;
  tokenUrl: string;

  constructor(private injector : Injector, public settings: UserSettings) {
    this.tokenHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.tokenUrl = localStorage.getItem("BASEURL") + `service/token`;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
    this.auth = this.injector.get(AuthenticationService);    
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });

    //return next.handle(request).pipe();

    return next.handle(request).pipe();
  }

    /*return next.handle(request)
      .pipe(
      catchError((error, ca) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
            if(this.auth.getUser() != null){
              return this.handle401Error(request, next);
            }  
            else
            {
              return Observable.throw(error);
            }
            default:
              return Observable.throw(error);
          }
        } else {
          return Observable.throw(error);
        }
      })
      )
  }*/
  
  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      const http = this.injector.get(HttpClient);

      let tokenRequest = 'grant_type=password&username=' + this.auth.getUser().username + '&password=' + this.auth.getUser().password;

      return http.post(this.tokenUrl,tokenRequest, {headers: this.tokenHeaders}).pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.auth.setToken(newToken)
            this.tokenSubject.next(newToken)
            return next.handle(req);
          }
          this.auth.logout()
          console.log('Could not refresh token 1')
          return Observable.create();
        }),
        catchError(error => {
          this.auth.logout()
          console.log('Could not refresh token 2')
          return Observable.create();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
        )
    } else {
      return this.tokenSubject
        .pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(req);
        })
        )
    }
  }
  
}