import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSettings } from '../../helpers/userSettings';
import { User } from 'src/models/data.types';
import { AlertCtrl } from 'src/helpers/alertController';
import { environment } from 'src/environments/environment';
import { NetworkService } from 'src/services/network.service/network.service';
import { UserService } from 'src/services/user.service/user.service';

@Component({
  selector: 'page-settings',
  templateUrl: 'settings.html',
})
export class SettingsPage implements OnInit{
  
  @ViewChild('appHeader') appHeaderRef;

  user:User;

  username:string;
  savedPages:any;
  pagesCount:number;
  domain;
  homePage:string;
  native:boolean;
  useSplitPane:boolean;
  allwareVersion:number = 4.4;

  constructor(
    public settings: UserSettings,
    private alert:AlertCtrl,
    private networkService:NetworkService,
    private userService:UserService)
    {
      this.native = environment.native;
      
      this.settings.getSplitPane().then(res=>{
        this.useSplitPane = res;
      })
    }

    ngOnInit(){

      this.networkService.cast.subscribe(connection=>{
        this.appHeaderRef.loadHeader(connection);
      })

      this.userService.cast.subscribe(user=>{
        if (user){
          this.user = user;
          this.username = this.user.username;
          this.homePage = this.user.homePage;
          this.domain = environment.domainName;
        }
        else {
          this.user = null;
          this.username = "N/A";
          this.homePage = "N/A";
          this.domain="N/A";
        }
      })
    }
}
