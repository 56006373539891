import { Network } from '@ionic-native/network/ngx';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AlertCtrl } from 'src/helpers/alertController';
import { Toast } from 'src/helpers/toastController';

@Injectable()
export class NetworkService{
    private internetConnection: boolean;
    private online = new ReplaySubject<boolean>(1)
    cast = this.online.asObservable()
    constructor(private network: Network, private alrt: AlertCtrl, private toast: Toast) {}

    // watch network for a disconnection
    private disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected :-(');
      this.internetConnection = false;
      this.online.next(this.internetConnection)
    });
    
    // watch network for a connection
    private connectSubscription = this.network.onConnect().subscribe(() => {
      console.log('network connected!');
      this.internetConnection = true;
      setTimeout(() => {
        if (this.network.type === 'wifi') {
          console.log('we got a wifi connection, woohoo!');
          this.online.next(this.internetConnection)
        }
      }, 500);
    });

    changeConnection(connection){
      //if internet connection, the user can change the connection
      if (this.internetConnection){
        this.online.next(connection)
        
        if (connection){
          this.toast.prompt("Online.", "success", 1000)
        }
        else{
          this.toast.prompt("Offline.", "danger", 1000)
        }
      }
      else{
        this.online.next(false)
        this.toast.prompt("No internet connection.", "warning", 2000)
      } 
    }
}


/*
import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Storage } from '@ionic/storage';

@Injectable()
export class NetworkProvider {

  constructor(public network: Network, private storage: Storage) {
    this.storage.get("server_connection").then(res => { 
        if (res === null){
            this.storage.set("server_connection", this.internetConnection());
        }
    })
   }

    internetConnection(){
        if (this.network.type == this.network.Connection.NONE){
            return false;
        }    
        else{
            return true;
        }
    }

    serverConnection(): Promise<boolean> {
        return this.storage.get("server_connection").then(res => { 
            if (res === null){
                return this.internetConnection();
            }
            else {
                return res;
            }
        })
    }

}*/