import { Component, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { UserSettings } from 'src/helpers/userSettings';
import { ApiService } from 'src/services/api.service/api.service';
import { DynamicPage, User } from 'src/models/data.types';
import { LocalApiService } from 'src/services/local-api.service/local-api.service';
import { Storage } from '@ionic/storage'
import { AppRoutingModule } from 'src/app/app-routing.module';
import { environment } from 'src/environments/environment';
import { NetworkService } from 'src/services/network.service/network.service';
import { UserService } from 'src/services/user.service/user.service';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { Toast } from 'src/helpers/toastController';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

//WRONG WAY TO DO THIS... SHOULD USER MULITPLE ROUTER OUTLET CONCEPT
export class HeaderComponent{

  @Output() refreshPage = new EventEmitter;

  private online:boolean;
  public title:string ;
  public downloaded:boolean;
  private native:boolean;
  public user:User;
  private pageRoute:string;
  showDefaultToolbar:boolean = false;

  constructor(
    public location:Location, 
    public settings: UserSettings, 
    private api: ApiService, 
    private localApi: LocalApiService, 
    public storage: Storage,
    private toast: Toast,
    private nav: AppRoutingModule,
    private networkService: NetworkService,
    private userService: UserService
    ) { 
    //set native flag for UI
    this.native = environment.native;    
  }

  loadHeader(pageRoute){
    combineLatest(this.userService.cast, this.networkService.cast, (user, connection)=> ({user, connection})).pipe(take(1))
    .subscribe(res=>{   

      this.user = res.user;
      this.online = res.connection;
      this.pageRoute = pageRoute;

      //is toolbar enabled?
      if (this.online) {

        this.api.getPageToolbarByRoute(this.pageRoute).then(res=>{
        
          //if hide toolbar
          if (!res && this.user.isDeveloper){
            this.showDefaultToolbar = true
          }
          else {
            this.showDefaultToolbar = false;
          }
        })
      }
      
      this.getPageTitle();
      
    })
  }

  /************************************************************* Toolbar Methods ***************************************************************************/
  private getPageTitle(){

    if (this.online){
      //get page title from api
      this.api.getPageTitleByRoute(this.pageRoute).then(res=>{
        if (res){
          this.title = res;
        }
        else{
          this.title = this.pageRoute;
        }

        //search for page in local database
        this.localApi.getPage(this.pageRoute).then((page:DynamicPage)=>{
          if (page){
            this.downloaded = true;
          }
          else {
            this.downloaded = false;    
          }
        })
      })  
    }
    else {
      //search for page in local database and get title from
      this.localApi.getPage(this.pageRoute).then((page:DynamicPage)=>{
        if (page){
          this.title = page.NAME;
          this.downloaded = true;
        }
        else {
          this.title = this.pageRoute;
          this.downloaded = false; 
        }
      })
    }
  }

  private openPageBuilder(){
    this.api.getDynamicPageByRoute(this.pageRoute).then(res=>{
      console.log("opening '" + this.pageRoute + "' with '" + environment.pageBuilderRoute +"'")
      this.nav.root(environment.pageBuilderRoute, {pageID:res.ID})
    });  
  }

  private downloadPage(){
    this.api.getDynamicPageByRoute(this.pageRoute).then(res=>{

      this.localApi.savePage(res).then(res=>{
        if (res){
          this.toast.prompt(this.pageRoute + " was downloaded.", "primary", 2000)
          this.downloaded = true;
        }
        else {
          console.log("is the local database available?")
          this.toast.prompt(this.pageRoute + " was not downloaded; local database not available.", "warning", 2500)
          this.downloaded = false;
        }
      }).catch(err=>{
        console.log(err);
        this.toast.prompt(this.pageRoute + " was not downloaded. ", "warning", 2000)
        this.downloaded = false;
      });
    });  
  }

  refresh(){
    this.loadHeader(this.pageRoute)
    this.refreshPage.emit();
    this.toast.prompt("Refreshing page...", "primary", 1000)
  }

  setConnection(connection){
    this.networkService.changeConnection(connection);
    this.loadHeader(this.pageRoute)
    this.refreshPage.emit();
  }

}
